import Flex from "components/grid/Flex";
import { Button, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { queryFormContext } from "./filter-state/context";
import { FilterBuilder } from "./FilterBuilder";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import { QueryHitsCounter } from "./QueryHitsCounter";
import CohortPatientList from "./CohortPatientList";
import React from "react";
import { HmoFilledButton } from "components/button/HmoFilledButton";

const CohortFilter = (props) => {
    const { t: translate } = useTranslation();
    const {
        name,
        setName,
        save,
        saveButtonText,
        toggleIsFilterOpen,
        formTree,
        dispatch,
        data,
        clinics,
        isLoading,
        showResultsForQuery,
        selectedClinicId,
        setSelectedClinicId,
        showResults,
        error
    } = props;
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    return (
        <Flex item container column>
            <Flex item container center>
                <TextField
                    style={{ paddingTop: 10, paddingBottom: 10, marginRight: 20 }}
                    value={name}
                    fullWidth
                    onChange={onNameChange}
                    placeholder={translate("cohort.name-placeholder")}
                />
                <HmoFilledButton style={{ width: 150 }} onClick={save} disabled={!name}>
                    {saveButtonText}
                </HmoFilledButton>
            </Flex>
            <Flex
                item
                container
                style={{
                    border: "1px solid lightgrey",
                    backgroundColor: "#F6F8FA",
                }}
            >
                <Flex item grow={1} container column style={{ flex: "0 0 80%" }}>
                    <Flex item={"0 0 0"} container>
                        <IconButton onClick={toggleIsFilterOpen} style={{ padding: 3 }}>
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <Typography style={{ fontWeight: "bold" }}>
                            {translate("cohort.create-filter-according")}
                        </Typography>
                    </Flex>
                    <queryFormContext.Provider value={{ formTree, dispatch }}>
                        <FilterBuilder {...{ translate }} />
                    </queryFormContext.Provider>
                    <Button
                        style={{ margin: 15, width: 200, textTransform: "none", textWrap: 'nowrap' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!data}
                        onClick={showResults}
                    >
                        {translate("cohort.show-result")}
                    </Button>
                </Flex>
                <Flex item container column center>
                    <DefaultTextField
                        label=""
                        select
                        SelectProps={{
                            value: clinics.find((clinic) => clinic.id === selectedClinicId)?.id || 0,
                            onChange: (event, value) => setSelectedClinicId(event.target.value),
                        }}
                        style={{ width: 200 }}
                    >
                        <MenuItem key={"Empty"} value={0}>
                            {translate("cohort.all-clinics")}
                        </MenuItem>
                        {clinics.map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id}>
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </DefaultTextField>
                    <QueryHitsCounter {...{ isLoading, error, count: data }} />
                </Flex>
            </Flex>
            {showResultsForQuery && <CohortPatientList {...{ query: showResultsForQuery }} />}
        </Flex>
    );
};

export default CohortFilter;
