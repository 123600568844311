import { TextField, Box } from "@material-ui/core";
import { Trans } from "react-i18next";
import { t } from "i18next";
export const RangeRenderer = ({ config, rule, idx, handleUpdateRule }) => (
    <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
        <Trans i18nKey={config.label || rule.properties.field} />
        <TextField
            type="number"
            size="small"
            style={{ width: config.width ? config.width + "px" : undefined }}
            placeholder={t("global.from")}
            value={rule.properties.value.from}
            onChange={(e) =>
                handleUpdateRule(idx, {
                    ...rule.properties,
                    value: {
                        type: rule.properties.value.type,
                        from: e.target.value === "" ? "" : Number(e.target.value),
                        to: rule.properties.value.to,
                    },
                })
            }
        ></TextField>
        <span style={{ alignSelf: "center", fontWeight: "bold", fontSize: "1.1em" }}>-</span>
        <TextField
            type="number"
            size="small"
            style={{ width: config.width ? config.width + "px" : undefined }}
            placeholder={t("global.to")}
            value={rule.properties.value.to}
            onChange={(e) =>
                handleUpdateRule(idx, {
                    ...rule.properties,
                    value: {
                        type: rule.properties.value.type,
                        from: rule.properties.value.from,
                        to: e.target.value === "" ? "" : Number(e.target.value),
                    },
                })
            }
        ></TextField>
    </Box>
);
