export const WidgetType = {
    DROPDOWN: "dropdown",
    RANGE_FILTER: "rangeFilter",
    SWITCH: "switch",
    SWITCH_WITH_SELECT: "switchWithSelect",
    SIMPLE_NUMBER: "simpleNumber",
    AUTOCOMPLETE: "autocomplete",
    TERM: "term",
    NESTED: "nested"
}
