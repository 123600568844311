import Flex from "components/grid/Flex";
import { Checkbox, Typography } from "@material-ui/core";
import utils from "../../utils/Utils";
import { Cell } from "../managed-care/PatientRow";
import { useSelector } from "react-redux";
import Tag from "components/tags/Tag";


const CohortPatientRow = (props) => {
    const { checkedPatientIds, patient, style, selectPatient, checkPatient, handleOnRightClick } = props;
    const careProviders = useSelector((state) => state.careProviderReducer.careProviders);
    const patientCareProviderIds = patient?.careProviderIds?.split(",") || [];
    const patientCareProviders = careProviders
        .filter((cp) => patientCareProviderIds.includes("" + cp.id))
        .map((cp) => cp.name)
        .join(" ,");
    const checked = checkedPatientIds.includes(patient.patientId);
    const onCheck = (event) => {
        event.stopPropagation();
        event.preventDefault();
        checkPatient(patient.patientId);
    };
    return (
        <div style={style}>
            <Flex
                item
                grow={0}
                container
                style={{
                    border: "1px solid #E2E1E4",
                    borderTop: "unset",
                    height: 40,
                    backgroundColor: "white",
                }}
                onClick={selectPatient(patient.patientId)}
                onContextMenu={handleOnRightClick}
            >
                <Flex item={"1 0 5%"} container style={{ width: "5%", alignItems: "center" }}>
                    <Checkbox
                            {...{
                                size: "small",
                                checked,
                                onClick: onCheck,
                            }}
                    ></Checkbox>
                    <Typography
                        style={{
                            color: !patient?.userId ? "darkgray" : "#030303",
                            opacity: 1,
                            bold: "unset",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontStyle: !patient?.userId ? "italic" : "initial",
                        }}
                    >
                        {utils.formattedUserNameWithComma(patient)}&nbsp;
                    </Typography>
                </Flex>
                <Cell left center={false} {...{ text: patientCareProviders || 'HMO'}} />
                <Cell left center={false} >
                    {(patient?.programs?.split(",") || []).map((tag) => {
                        return <Tag {...{ tag }} />;
                    })}
                </Cell>

                <Cell left center={false} {...{ text: patient?.age }} />
                <Flex item={"1 0 5%"} container style={{ width: "5%", alignItems: "center" }}/>
            </Flex>
        </div>
    );
};

export default CohortPatientRow;
