import bodybuilder from "bodybuilder";

const ruleHandlers = {
    number: numberHandler,
    string: termHandler,
    boolean: termHandler,
    nested: nestedHandler,
    dateRange: dateRangeHandler,
    range: rangeHandler,
    switchWithSelect: switchWithSelectHandler,
};

const shouldProcessRule = ({ properties: { value, disabled, operator } }) => {
    return !disabled && value !== "" && operator !== "";
};

const mergeRuleWithQuery = (query, rule) => {
    const ruleProperties = rule.properties;
    const value = ruleProperties.value;
    const handler = ruleHandlers[value.type || typeof value];
    return handler ? handler(query, ruleProperties) : query;
};

function buildElasticQuery(formTree) {
    return formTree.filter(shouldProcessRule).reduce(mergeRuleWithQuery, bodybuilder()).build();
}

function numberHandler(query, { value, field, operator }) {
    return operator === "eq" || operator === undefined
        ? query.filter("term", field, value)
        : query.filter("range", field, { [operator]: value });
}

function termHandler(query, { value, field, emptyAsFalse }) {
    if (value === false && emptyAsFalse) {
        return query.filter("bool", (b) => {
            b.orFilter("bool", "must_not", { exists: { field } });
            return b.orFilter("term", field, value);
        });
    } else {
        return query.filter("term", field, value);
    }
}

function switchWithSelectHandler(query, { value }) {
    return query.filter("bool", (b) => {
        b.filter("term", value.switchValue.field, value.switchValue.value);
        if (value.switchValue.value && value.selectValue.value) {
            b.filter("match", value.selectValue.field, value.selectValue.value);
        }
        return b;
    });
}

function dateRangeHandler(query, { value, field }) {
    return value.to === "" && value.from === ""
        ? query
        : query.filter("range", field, {
              lte: value.from ? `now-${value.from}y/d` : undefined,
              gte: value.to ? `now-${value.to}y/d` : undefined,
          });
}

function rangeHandler(query, { value, field }) {
    return value.from === "" && value.to === ""
        ? query
        : query.filter("range", field, {
              gte: value.from ? value.from : undefined,
              lte: value.to ? value.to : undefined,
          });
}

function nestedHandler(query, { value, field }) {
    const nestedRulesToProcess = value.nestedValues.filter(shouldProcessRule);
    return nestedRulesToProcess.length === 0 || nestedRulesToProcess.every((v) => v.properties.value === "")
        ? query
        : query.query("nested", { path: field }, (f) =>
              f.query("bool", (b) => nestedRulesToProcess.reduce(mergeRuleWithQuery, b)),
          );
}

export default buildElasticQuery;
