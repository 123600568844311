import HMOApi, { COHORTS, FILTERS } from "store/api/HMOApi";

export const CohortApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        getFilter: build.query({
            query: (id) => ({
                url: `filter/${id}`,
                method: "GET",
            }),
            providesTags: [FILTERS],
        }),
        getCohort: build.query({
            query: (id) => ({
                url: `cohort/${id}`,
                method: "GET",
            }),
            providesTags: [COHORTS],
        }),
        listFilters: build.query({
            query: () => ({
                url: `filters`,
                method: "GET",
            }),
            providesTags: [FILTERS],
        }),
        listCohorts: build.query({
            query: () => ({
                url: `cohorts`,
                method: "GET",
            }),
            providesTags: [COHORTS],
        }),
        addFilter: build.mutation({
            query: (props) => ({
                url: "filter",
                method: "PUT",
                data: props,
            }),
            invalidatesTags: [FILTERS],
        }),
        updateFilter: build.mutation({
            query: (props) => ({
                url: `filter`,
                method: "POST",
                data: props,
            }),
            invalidatesTags: [FILTERS],
        }),
        removeFilter: build.mutation({
            query: ( id ) => ({
                url: `filter/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [FILTERS],
        }),
        addCohort: build.mutation({
            query: (props) => ({
                url: "cohort",
                method: "PUT",
                data: props,
            }),
            invalidatesTags: [COHORTS],
        }),
        updateCohort: build.mutation({
            query: (props) => ({
                url: `cohort/${props.id}`,
                method: "POST",
                data: props,
            }),
            invalidatesTags: [COHORTS],
        }),
        removeCohort: build.mutation({
            query: (id ) => ({
                url: `cohort/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [COHORTS],
        }),
        getCountFromElastic: build.query({
            query: (props) => ({
                url: `elastic/get-count`,
                method: "POST",
                data: {
                    size: 10000,
                    ...props,
                },
            }),
        }),
        getPatientsFromElastic: build.query({
            query: (props) => ({
                url: `elastic/get-patients`,
                method: "POST",
                data: {
                    size: 10000,
                    ...props,
                },
            }),
        }),
        addPatientsToCohort: build.mutation({
            query: ({ cohortId, patientIds }) => ({
                url: `cohort/${cohortId}/add-patients`,
                method: "POST",
                data: patientIds,
            }),
            invalidatesTags: [COHORTS],
        }),
        removePatientsFromCohort: build.mutation({
            query: ({ cohortId, patientIds }) => ({
                url: `cohort/${cohortId}/delete-patients`,
                method: "POST",
                data: patientIds,
            }),
            invalidatesTags: [COHORTS],
        }),
        fetchDistinctFieldValues: build.query({
            query: ({field, nested}) => ({
                url: `elastic/get-distinct-field-values/${field}`,
                params: {nested},
            }),
        }),
    }),
});

export const {
    useGetFilterQuery,
    useGetCohortQuery,
    useListFiltersQuery,
    useListCohortsQuery,
    useAddFilterMutation,
    useUpdateFilterMutation,
    useRemoveFilterMutation,
    useAddCohortMutation,
    useUpdateCohortMutation,
    useRemoveCohortMutation,
    useGetCountFromElasticQuery,
    useGetPatientsFromElasticQuery,
    useAddPatientsToCohortMutation,
    useRemovePatientsFromCohortMutation,
    useFetchDistinctFieldValuesQuery,
} = CohortApi;
