import Flex from "components/grid/Flex";
import {Typography, Divider, Paper} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useFetchLaboratoryByPatientQuery} from "scenes/patient/laboratory/LaboratoryApi";
import {useFetchAnamnesisByPatientQuery} from "scenes/patient/anamnesis/AnamnesisApi";
import {useFetchPatientByIdQuery} from "scenes/patient/PatientApi";
import {riskProfileCalculator} from "scenes/patient/my-risk/calculators/RiskProfile.calculator";
import {riskProfileMapper, missingAnswers} from "scenes/patient/my-risk/calculators/RiskProfile.mapper";
import {chipCoefficient} from "scenes/patient/my-risk/calculators/ChipRisk.calculator";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import {Pie, Cell, PieChart, Tooltip} from "recharts";
import _ from "lodash";
import Accordion from "components/grid/Accordion";
import {isCaseManager} from "domain/User.model";
import {useSelector} from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import HematologyRisk from "./HematologyRisk";
import {useIsDebugView} from "../../../utils/useDebugView";

const HealthTile = (props) => {
    const {percent, title} = props;
    return (
            <Flex
                    item
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    style={{margin: 5, maxHeight: 40, padding: "5px 10px", borderRadius: 6, border: "1px solid grey"}}
            >
                <Typography>{title}</Typography>
                <Typography style={{color: "green"}}>-{_.round(percent, 0)}%</Typography>
            </Flex>
    );
};
const RiskProfileTooltip = (fullRiskPercent) => {
    const CustomTooltip = ({payload}) => {
        const {t: translate} = useTranslation();
        let title;
        let value = _.round(payload[0]?.value, 0);
        switch (payload[0]?.payload.id) {
            case "fullRiskPercent":
                title = translate("my-risk.fullRiskPercent");
                value = _.round(fullRiskPercent, 0);
                break;
            case "patientRiskPercent":
                title = translate("my-risk.patientRiskPercent");
                break;
            case "empty":
                title = "";
                break;
            case "healthyRiskPercent":
                title = translate("my-risk.healthyRiskPercent");
                break;
            default:
                title = "";
        }

        return title ? <span style={{fontSize: 14, fontWeight: "bold"}}>{title + ": " + value + "%"}</span> : "";
    };
    return CustomTooltip;
};

const RiskProfile = ({currentUser, translate, patientData, laboratoryData, anamnesisData, isEmpty}) => {
    const debugView = useIsDebugView();
    const input = riskProfileMapper(patientData, anamnesisData, laboratoryData);
    const {user, ...rest} = riskProfileCalculator().get(input.age, input.sex, input.riskValues);
    const output = rest;
    const outUser = user.replaceAll("\n", "").replace(" ", "");
    const patientRisk = output?.user_value;
    const patientRiskWithoutSmoking = riskProfileCalculator().get(input.age, input.sex, {
        ...input.riskValues,
        Smoker: false,
    })?.user_value;
    const patientRiskWithNormalBloodPreassure = riskProfileCalculator().get(input.age, input.sex, {
        ...input.riskValues,
        BloodPressureSystolic: 120,
    })?.user_value;
    const patientRiskWithHealthyLifeStyle = riskProfileCalculator().get(input.age, input.sex, {
        ...input.riskValues,
        Smoker: false,
        BloodPressureSystolic: 120,
    })?.user_value;
    const chipRisk = chipCoefficient(anamnesisData);
    const fullRisk = patientRisk * chipRisk;
    const healthyRisk = patientRiskWithHealthyLifeStyle * chipRisk;
    const patientRiskPercent = Math.min(patientRisk * 100, 100);
    const fullRiskPercent = Math.min(fullRisk * 100, 95);
    const healthyRiskPercent = Math.min(healthyRisk * 100, 100);
    const noSmokeRiskPercent = Math.min(patientRiskWithoutSmoking * chipRisk * 100, 100);
    const lowPressureRiskPercent = Math.min(patientRiskWithNormalBloodPreassure * chipRisk * 100, 100);

    const fixedSmoking = fullRiskPercent - noSmokeRiskPercent;
    const fixedBloodPressure = fullRiskPercent - lowPressureRiskPercent;
    const fixedBoth = fullRiskPercent - healthyRiskPercent;
    const width = 340;
    const roundedFullRiskPercent = _.round(fullRiskPercent, 0);
    const isHealthyRiskVisible =
            healthyRiskPercent < fullRiskPercent &&
            (input?.riskValues?.Smoker || input?.riskValues?.BloodPressureSystolic > 120);
    return <>
        <Flex item grow={0} container column style={{marginBottom: isEmpty ? 0 : 25, maxWidth: width}}>
            <Typography style={{fontSize: 24, fontWeight: "bold", textAlign: "center"}}>
                {translate("global.cardiovascular")}
            </Typography>
            <Flex item grow={0} container column fullWidth style={{height: width, width}}>
                <PieChart width={width} height={width}>
                    <text
                            x={"50%"}
                            y={"50%"}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fontSize: roundedFullRiskPercent === 100 ? width / 3.3 : width / 2.5,
                                fontWeight: "bold",
                            }}
                    >
                        {isEmpty &&
                                <tspan
                                        style={{fontSize: width / 10, fill: '#d7d7d7'}}
                                >
                                    {translate('my-risk.not-verifiable')}
                                </tspan>

                        }
                        {
                                !isEmpty &&
                                <>
                                    {roundedFullRiskPercent}
                                    <tspan
                                            dy={roundedFullRiskPercent === 100 ? "0.35em" : "0.74em"}
                                            style={{fontSize: width / 10, fontWeight: "normal"}}
                                    >
                                        %
                                    </tspan>
                                </>
                        }

                    </text>
                    <Pie
                            isAnimationActive={false}
                            data={isEmpty ? [{percent: 100}] : [
                                {percent: 100 - fullRiskPercent},
                                {
                                    percent:
                                            fullRiskPercent !== patientRiskPercent
                                                    ? fullRiskPercent - patientRiskPercent
                                                    : fullRiskPercent,
                                },
                                {percent: fullRiskPercent !== patientRiskPercent ? patientRiskPercent : 0},
                            ]}
                            dataKey="percent"
                            innerRadius="78%"
                            outerRadius="100%"
                            startAngle={-270}
                            paddingAngle={0}
                    >
                        <Cell id={"empty"} fill={"#f2f2f2"}/>
                        <Cell id={"fullRiskPercent"} fill={isEmpty ? "#f2f2f2" : "#ac0000"}/>
                        {fullRiskPercent !== patientRiskPercent && !isEmpty && (
                                <Cell id={"patientRiskPercent"} fill={"#e50000"}/>
                        )}
                    </Pie>
                    {isHealthyRiskVisible && !isEmpty && (
                            <Pie
                                    isAnimationActive={false}
                                    data={[{percent: 100 - healthyRiskPercent}, {percent: healthyRiskPercent}]}
                                    dataKey="percent"
                                    innerRadius="70%"
                                    outerRadius="80%"
                                    startAngle={-270}
                                    paddingAngle={0}
                            >
                                <Cell id={"empty"} fill={"white"}/>
                                <Cell id={"healthyRiskPercent"} fill={"#83bb48"}/>
                            </Pie>
                    )}

                    <Tooltip content={RiskProfileTooltip(fullRiskPercent)} position={{x: 300, y: 0}}/>
                </PieChart>
            </Flex>
            <Typography style={{fontSize: 12, textAlign: "center"}}>
                {translate("my-risk.provided-by")}
                <a target={"_blank"} href={"https://herzfit.dev.digital-medicine.org/"}>
                    Deutsche Herzstiftung
                </a>
            </Typography>

        </Flex>
        {
                !isEmpty &&
                <Flex item grow={0} container column>
                    <Typography style={{fontWeight: "bold", fontSize: 16}}>
                        {translate("my-risk.risk-text") + _.round(fullRiskPercent, 0) + "%"}
                    </Typography>
                    {(input?.riskValues?.Smoker || input?.riskValues?.BloodPressureSystolic > 120) &&
                    (fixedSmoking > 0 || fixedBloodPressure > 0 || fixedBoth > 0) ? (
                            <>
                                <Divider style={{margin: "20px 10px"}}/>
                                <Typography
                                        style={{fontWeight: "bold"}}>{translate("my-risk.reduce-risk-text")}?</Typography>
                                <Flex
                                        item
                                        container
                                        justifyContent={"space-between"}
                                        style={{padding: "0px 15px", marginTop: 10, maxHeight: 40}}
                                >
                                    <HmoInputLabel>{translate("global.action")}</HmoInputLabel>
                                    <HmoInputLabel>{translate("my-risk.optimization")}</HmoInputLabel>
                                </Flex>
                                {input?.riskValues?.Smoker && fixedSmoking > 0 && (
                                        <HealthTile percent={fixedSmoking}
                                                    title={translate("my-risk.stop-smoking")}/>
                                )}
                                {input?.riskValues?.BloodPressureSystolic > 120 && fixedBloodPressure > 0 && (
                                        <HealthTile percent={fixedBloodPressure}
                                                    title={translate("my-risk.fix-blood-pressure")}/>
                                )}
                                {input?.riskValues?.Smoker &&
                                        input?.riskValues?.BloodPressureSystolic > 120 &&
                                        fixedBoth > 0 &&
                                        <HealthTile percent={fixedBoth} title={translate("my-risk.both")}/>}
                            </>
                    ) : (
                            <>
                                <Divider style={{margin: "20px 10px"}}/>
                                <Typography style={{fontWeight: "bold"}}>
                                    {translate("my-risk.no-reduction-possible")}
                                </Typography>
                            </>
                    )}
                </Flex>
        }

        {
                debugView && <Accordion
                        openByDefault={true}
                        title={`Risk: ${_.round(patientRisk * 100, 4)}, No Smoke: ${_.round(
                                patientRiskWithoutSmoking * 100,
                                4
                        )}, No High: ${_.round(patientRiskWithNormalBloodPreassure * 100, 5)}, Both: ${_.round(
                                patientRiskWithHealthyLifeStyle * 100,
                                4
                        )}, CHIP: ${chipRisk}`}
                >
                    <Flex item container column>
                        <Flex item container>
                            <pre style={{fontWeight: "bold"}}>Input: {JSON.stringify(input, null, 2)}</pre>
                            {outUser && (
                                    <pre style={{fontWeight: "bold"}}>
                                        Output User: {JSON.stringify(JSON.parse(outUser), null, 2)}
                                    </pre>
                            )}
                            <pre style={{fontWeight: "bold"}}>Output: {JSON.stringify(output, null, 2)}</pre>
                            <pre
                                    style={{
                                        fontWeight: "bold",
                                        textWrap: "balance",
                                    }}
                            >
                                    Output no smoke:{" "}
                                {JSON.stringify(
                                        riskProfileCalculator().get(input.age, input.sex, {
                                            ...input.riskValues,
                                            Smoker: false,
                                        }),
                                        null,
                                        2
                                )}
                                </pre>
                        </Flex>
                    </Flex>
                </Accordion>

        }
    </>;
};

const PlaceHolder = () => {
    return <>
        <Skeleton variant="text" height={"2em"}/>
        <Skeleton variant="rect" height={"266px"}/>
    </>;
};

const MissingMyRiskAnswers = ({answersMissing, translate}) => {
    const anamnesisMissing = answersMissing?.filter((key) => key.startsWith("anamnesis"));
    const laboratoryMissing = answersMissing?.filter((key) => key.startsWith("laboratory"));
    return <>
        <Typography variant={"h6"} style={{margin: "20px 0px"}}>
            {translate("my-risk.missing-answers")}
        </Typography>
        {
                !!anamnesisMissing.length &&
                <Paper style={{padding: 10, width: '100%'}} elevation={4}>
                    <Typography style={{
                        margin: "5px 0px",
                        fontWeight: "bold",
                        fontSize: 16
                    }}>
                        {translate("patient.anamnase-questionaire")}
                    </Typography>
                    <ul>
                        {anamnesisMissing.map((translateKey) => (
                                <li key={translateKey}>
                                    <Typography key={translateKey}
                                                style={{fontWeight: "bold"}}>
                                        {translate(translateKey)}
                                    </Typography>
                                </li>
                        ))}
                    </ul>
                </Paper>
        }
        {
                !!laboratoryMissing.length &&
                <Paper style={{padding: 10, marginTop: 10, width: '100%'}} elevation={4}>
                    <Typography style={{
                        margin: "5px 0px",
                        fontWeight: "bold",
                        fontSize: 16
                    }}>
                        {translate("patient.laboratory-history")}
                    </Typography>
                    <ul>
                        {laboratoryMissing.map((translateKey) => (
                                <li key={translateKey}>
                                    <Typography
                                                style={{fontWeight: "bold"}}>
                                        {translate(translateKey)}
                                    </Typography>
                                </li>
                        ))}
                    </ul>
                </Paper>
        }
    </>;
};

const MyRisk = () => {
    const user = useSelector((state) => state.mainReducer.user);
    const {t: translate} = useTranslation();
    const {patientId} = useParams();
    const {data: patientData, isSuccess: patientDataLoadedSuccessfully} = useFetchPatientByIdQuery(patientId, {
        refetchOnMountOrArgChange: true,
    });
    const {data: laboratoryData, isSuccess: laboratoryDataLoadedSuccessfully} = useFetchLaboratoryByPatientQuery(
            {patientId},
            {skip: !patientId, refetchOnMountOrArgChange: true}
    );
    const {data: anamnesisData, isSuccess: anamnesisDataLoadedSuccessfully} = useFetchAnamnesisByPatientQuery(
            {patientId},
            {skip: !patientId, refetchOnMountOrArgChange: true}
    );

    const answersMissing =
            laboratoryDataLoadedSuccessfully && anamnesisDataLoadedSuccessfully
                    ? missingAnswers(laboratoryData, anamnesisData)
                    : [];


    const dataIsLoaded =
            patientDataLoadedSuccessfully && laboratoryDataLoadedSuccessfully && anamnesisDataLoadedSuccessfully;
    const isEmpty = answersMissing?.length > 0;
    return <Flex item container>
        <Flex item container column flex={1} style={{padding: 20, maxWidth: '50%'}} alignItems="center">
            {
                    dataIsLoaded && <>
                        <RiskProfile
                                {...{
                                    currentUser: user,
                                    translate,
                                    patientData,
                                    laboratoryData,
                                    anamnesisData,
                                    isEmpty
                                }}
                        />
                        {
                                isEmpty &&
                                <MissingMyRiskAnswers {...{translate, answersMissing}}/>
                        }
                    </>
            }
            {!dataIsLoaded && <PlaceHolder/>}
        </Flex>
        <Flex item container flex={1} column style={{padding: 20, maxWidth: '50%'}}>
            {
                dataIsLoaded
                        ? <HematologyRisk
                                patientData={patientData}
                                mutationData={anamnesisData?.chipMutationData}
                                laboratoryData={laboratoryData}
                        />
                        : <PlaceHolder/>
            }
        </Flex>
    </Flex>;
};

export default MyRisk;
