import { geneValues } from "../../patient/anamnesis/component/section/MutationModalForm";
import { ProblemSeverityEnum, DiscomfortLevelEnum } from "../../patient/anamnesis/enums";
import { MentalDiscomfortLevelEnum } from "../../patient/anamnesis/enums/MentalDiscomfortLevel.copy";
import { Category } from "./categories";
import { WidgetType } from "./widgetTypes";

const dropDownValues = {
    treatmentStatus: [
        { value: "TREATED", label: "global.treated" },
        { value: "UNTREATED", label: "global.untreated" },
    ],
    problemSeverity: Object.values(ProblemSeverityEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    mentalProblemsSeverity: Object.values(MentalDiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    discomfortLevel: Object.values(DiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
};

export const fields = {
    bio_sex: {
        widgetType: WidgetType.DROPDOWN,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.genderLabel",
            operators: ["equal"],
            values: [
                { value: "MAN", label: "global.male-bio-sex" },
                {
                    value: "WOMAN",
                    label: "global.female-bio-sex",
                },
            ],
        },
    },

    "anamnesis.GENERAL.height": {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.heightLabel",
            width: 56,
        },
    },
    "anamnesis.GENERAL.weight": {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.weightLabel",
            width: 56,
        },
    },
    "anamnesis.CARDIO_DISEASE.hasHealthyDiet": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasHealthyDiet",
        },
    },
    "anamnesis.CARDIO_DISEASE.hasTimePressureAndStress": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasTimePressureAndStress",
        },
    },
    "anamnesis.CCS": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.RISK_SCORE,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CCS.hasCCS",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CCS.ccsValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.clinical.CCSValueLabelInQuery",
            values: ["CCS2", "CCS3", "CCS4"],
        },
    },

    "anamnesis.RISK_FACTOR.chipMutationData.values_count": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        category: Category.CHIP,
        widgetConfig: {
            width: 48,
            label: "anamnesis.chip.mutationCount",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    age: {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        defaultRuleProperties: {
            value: {
                type: "dateRange",
                from: 18,
                to: 40,
            },
        },
        widgetConfig: {
            width: 56,
            label: "global.age",
        },
    },
    "anamnesis.CARDIOVASCULAR.smokeStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.smokeStatus",
        },
    },
    "anamnesis.CARDIOVASCULAR.oldSmokeStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.oldSmokeStatus",
        },
    },
    "anamnesis.CARDIOVASCULAR.alcoholDrinkingStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.alcoholDrinkingStatus",
        },
    },

    "anamnesis.NYHA": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.RISK_SCORE,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.NYHA.hasNYHA",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.NYHA.nyhaValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.clinical.NYHAValueLabelInQuery",
            values: ["NYHA2", "NYHA3", "NYHA4"],
        },
    },
    "anamnesis.QUALITY_OF_LIFE.dailyActivityValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.dailyActivityValueShort",
            values: dropDownValues.problemSeverity,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values": {
        widgetType: WidgetType.NESTED,
        category: Category.CHIP,
        defaultRuleProperties: {
            value: {
                type: "nested",
                nestedValues: [
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
                            operator: "eq",
                            value: "",
                        },
                    },
                ],
            },
        },
        widgetConfig: {
            label: "anamnesis.chip.mutationData",
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.gene": {
        widgetType: WidgetType.DROPDOWN,
        widgetConfig: {
            width: 150,
            label: "anamnesis.chip.table.gene",
            values: geneValues,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.vaf": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        widgetConfig: {
            width: 80,
            label: "anamnesis.chip.table.vaf",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.exon": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        widgetConfig: {
            label: "Exon",
            width: 50,
            operators: [],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.dna": {
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label: "anamnesis.chip.table.dna",
            width: 150,
            autoLoadValues: true
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.protein": {
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label: "anamnesis.chip.table.protein",
            width: 150,
            autoLoadValues: true
        },
    },
    "anamnesis.COMBINED.haveAllergies": {
        widgetType: WidgetType.SWITCH,
        category: Category.OTHER,
        widgetConfig: {
            label: "anamnesis.allergies.title",
        },
    },
    "anamnesis.CARDIOVASCULAR.hasCardiovascularDisease": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasCardiovascularDisease",
        },
    },
    "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.CARDIO_RISK_FACTORS,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CARDIOVASCULAR.diabetesState",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "Diabetes mellitus",
            values: dropDownValues.treatmentStatus,
        },
    },
    "anamnesis.COMBINED.haveAsthma": {
        widgetType: WidgetType.SWITCH,
        category: Category.COMORBIDITIES,
        widgetConfig: {
            label: "anamnesis.comorbidity.asthma",
        },
    },
    "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.CARDIO_RISK_FACTORS,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CARDIOVASCULAR.hypercholesterolemiaState",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.cardiovascular.hypercholesterolemia",
            values: dropDownValues.treatmentStatus,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.anxiousValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.anxiousOrDepressive",
            values: dropDownValues.mentalProblemsSeverity,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.discomfortValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.painOrDiscomfort",
            values: dropDownValues.discomfortLevel,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.washAndDressValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.selfCareAbility",
            values: dropDownValues.problemSeverity,
        },
    },
    "anamnesis.CARDIOVASCULAR.sportStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.sportStatus",
        },
    }
};
