export const Category = {
    "GENERAL": "general",
    "OTHER": "other",
    "CHIP": "chip",
    "CARDIO_RISK_FACTORS": "cardioRiskFactors",
    "QUALITY_OF_LIFE": "qualityOfLife",
    "RISK_SCORE": "riskScore",
    "COMORBIDITIES": "comorbidities",
 }

 export const categoryLabels = {
    [Category.GENERAL]: "anamnesis.general.title",
    [Category.OTHER]: "global.other",
    [Category.CHIP]: "anamnesis.chip.title",
    [Category.CARDIO_RISK_FACTORS]: "anamnesis.cardiovascular.title",
    [Category.QUALITY_OF_LIFE]: "anamnesis.quality.shortTitle",
    [Category.RISK_SCORE]: "my-risk.risk-score",
    [Category.COMORBIDITIES]: "anamnesis.comorbidity.title"
 }
