import { useContext } from "react";
import { DisableToggle } from "./searchInputRenderers/shared/DisableToggle";
import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { queryFormContext } from "../filter-state/context";
import { fields } from "../filter-configs/fields.conf";
import { inputRendererMap } from "../filter-configs/inputRendererMap";
import { REMOVE_RULE, UPDATE_RULE } from "../filter-state/actions";
import { Trans } from "react-i18next";
import { categoryLabels } from "../filter-configs/categories";
import {useTheme} from "@material-ui/core/styles";

function categorizeRules(rules) {
    return rules.reduce((categorized, rule, index) => {
        const fieldConfig = fields[rule.properties.field];
        return fieldConfig
            ? !categorized[fieldConfig.category]
                ? { ...categorized, [fieldConfig.category]: [{ rule, index, config: fieldConfig }] }
                : {
                      ...categorized,
                      [fieldConfig.category]: [
                          ...categorized[fieldConfig.category],
                          { rule, index, config: fieldConfig },
                      ],
                  }
            : categorized;
    }, {});
}



export const FiltersByCategoryRenderer = () => {
    const { dispatch, formTree } = useContext(queryFormContext);
    const theme = useTheme();
    const categorizedComponents = categorizeRules(formTree);
    const handleUpdateRule = (idx, updatedProperties) => {
        dispatch({
            type: UPDATE_RULE,
            index: idx,
            payload: {
                properties: updatedProperties,
            },
        });
    };
    return Object.entries(categorizedComponents).map(([categoryName, categoryValues]) => (
        <div
            style={{
                background: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderRadius: "4px",
                padding: theme.spacing(1),
                marginBottom: theme.spacing(2),
            }}
            key={categoryName}
        >
            <div style={{ fontWeight: "bold", fontSize: "1.2em", marginBottom: theme.spacing(1) }}>
                <Trans i18nKey={categoryLabels[categoryName] || categoryName} />
                </div>
            {categoryValues.map((componentData, idx) => {
                const Renderer = inputRendererMap[componentData.config.widgetType];
                return (
                    <div
                        key={idx}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <div>
                            <DisableToggle
                                rule={componentData.rule}
                                idx={componentData.index}
                                handleUpdateRule={handleUpdateRule}
                            />
                        </div>
                        <Renderer
                            config={componentData.config.widgetConfig}
                            rule={componentData.rule}
                            idx={componentData.index}
                            handleUpdateRule={handleUpdateRule}
                        />

                        <div
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() => {
                                dispatch({
                                    type: REMOVE_RULE,
                                    index: componentData.index,
                                });
                            }}
                        >
                            <IconButton size="small" style={{ marginBottom: "2px", color: theme.palette.error.light }}>
                                <HighlightOff />
                            </IconButton>
                        </div>
                    </div>
                );
            })}
        </div>
    ));
};
