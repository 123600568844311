import { useTranslation } from "react-i18next";
import React, { useEffect, useReducer, useState } from "react";
import formStructureReducer from "./filter-state/reducer";
import formStructureToElasticQuery from "./formStructureToElasticQuery";
import { useAddFilterMutation, useGetCountFromElasticQuery } from "./CohortApi";
import { useSelector } from "react-redux";
import { useEnqueueError, useEnqueueSuccess } from "../../components/alert/SnackbarHooks";
import CohortFilter from "./CohortFilter";

const CohortFilterNew = ({ route }) => {
    const [saveFilter] = useAddFilterMutation();
    const [formTree, dispatch] = useReducer(formStructureReducer, []);
    const { t: translate } = useTranslation();
    const [name, setName] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [selectedClinicId, setSelectedClinicId] = useState(0);
    const toggleIsFilterOpen = () => setIsFilterOpen(!isFilterOpen);
    const clinics = [];
    const elasticQuery = formStructureToElasticQuery(formTree);
    const { data, error, isLoading } = useGetCountFromElasticQuery(elasticQuery, { cacheTime: 30 });
    const currentUserId = useSelector((state) => state.mainReducer.user.id);
    const [showResultsForQuery, setShowResultsForQuery] = useState("");
    const enqueueSuccess = useEnqueueSuccess();
    const enqueueError = useEnqueueError();
    useEffect(() => {
        if(JSON.stringify(elasticQuery) !== JSON.stringify(showResultsForQuery)){
            setShowResultsForQuery("");
        }
    }, [elasticQuery]);
    const showResults = () => {
        setShowResultsForQuery(elasticQuery);
    };
    const save = () => {
        saveFilter({
            name,
            filterText: JSON.stringify(formTree),
            esQuery: JSON.stringify(elasticQuery),
            creatorUserId: currentUserId,
            creationDate: new Date(),
        }).then(() => {
            enqueueSuccess();
        })
        .catch(() => {
            enqueueError();
        })
    };
    return (
            <CohortFilter {...{
                name,
                setName,
                save,
                saveButtonText: translate("cohort.save"),
                toggleIsFilterOpen,
                formTree,
                dispatch,
                data,
                clinics,
                isLoading,
                showResultsForQuery,
                selectedClinicId,
                setSelectedClinicId,
                showResults,
                error
            }}/>
    );
};

export default CohortFilterNew;
