import Flex from "components/grid/Flex";
import {Button, Checkbox, Dialog, DialogTitle} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useAddCohortMutation, useGetPatientsFromElasticQuery } from "./CohortApi";
import CohortPatientRow from "./CohortPatientRow";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";
import React, { useEffect, useState } from "react";
import { PATIENT_PAGE } from "routes/routes";
import HeaderItem from "components/header-item/HeaderItem";
import _ from "lodash";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import { useEnqueueError, useEnqueueSuccess } from "components/alert/SnackbarHooks";
import NewProgramDialog from "../patient/NewProgramDialog";
import PatientRowContextMenu from "../managed-care/PatientRowContextMenu";

const SaveCohortDialog = (props) => {
    const { saveCohortDialogOpen, translate, setCohortName, cohortName, setSaveCohortDialogOpen, saveCohort } = props;
    return (
        <Dialog open={saveCohortDialogOpen} maxWidth={"lg"}>
            <DialogTitle style={{ paddingBottom: "2px" }}>{translate("cohort.save-cohort-dialog-title")}</DialogTitle>
            <Flex item container column style={{ minWidth: 700 }} padding={10}>
                <DefaultTextField
                    {...{
                        placeholder: translate("cohort.save-cohort-dialog-placeholder"),
                        fullWidth: true,
                        onChange: (event) => setCohortName(event.target.value),
                        value: cohortName,
                        label: translate("global.description"),
                    }}
                />
                <Flex item container justifyContent={"flex-end"} style={{ marginTop: 10 }}>
                    <Button
                        onClick={() => setSaveCohortDialogOpen(false)}
                        color="primary"
                        size={"small"}
                        variant="outlined"
                    >
                        <Trans i18nKey={"global.cancel"} />
                    </Button>
                    <Button
                        onClick={saveCohort}
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        disabled={!cohortName}
                        autoFocus
                        size={"small"}
                        color="primary"
                    >
                        <Trans i18nKey={"cohort.save-cohort"} />
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
};

const PatientHeader = (props) => {
    const { translate, onSort, sortParameter, reverseSort, allCurrentPatientsAreChecked, checkAllPatientToggle } =
        props;

    return (
        <Flex
            item
            container
            grow={0}
            style={{
                backgroundColor: "#F8F8FA",
                height: 40,
                marginTop: 10,
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid #E2E1E4",
            }}
        >
            <Checkbox size={'small'} style={{marginLeft: 5}} onClick={checkAllPatientToggle} checked={allCurrentPatientsAreChecked}/>
            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.name"),
                    sortName: "familyName",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.clinics"),
                    sortName: "clinics",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.programs"),
                    sortName: "programs",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.age"),
                    sortName: "age",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    title: translate("global.manage"),
                    sortName: "",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />
        </Flex>
    );
};

const Row = ({ data, index, style }) => {
    const { selectPatient, checkedPatientIds, checkPatient, rows, handleOnRightClick } = data;
    return (
        <CohortPatientRow
            key={index}
            {...{
                style,
                patient: rows[index],
                selectPatient,
                checkedPatientIds,
                checkPatient,
                handleOnRightClick
            }}
        />
    );
};

function PatientListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const { patients, selectPatient, checkedPatientIds, checkPatient, handleOnRightClick } = props;

    return (
        <div
            ref={ref}
            style={{
                height: "40vh",
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={patients.length}
                itemSize={40}
                width={width || 500}
                itemData={{
                    rows: patients,
                    selectPatient,
                    checkedPatientIds,
                    checkPatient,
                    handleOnRightClick
                }}
            >
                {Row}
            </FixedSizeList>
        </div>
    );
}

const CohortPatientList = ({ query }) => {
    const { data: patients = [], error, isLoading } = useGetPatientsFromElasticQuery(query, { cacheTime: 30 });
    const { t: translate } = useTranslation();
    const [checkedPatientIds, setCheckedPatientIds] = useState([]);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [allCurrentPatientsAreChecked, setAllCurrentPatientsAreChecked] = useState(false);
    const [isNewProgramDialogOpen, setIsNewProgramDialogOpen] = useState(false);
    const toggleNewProgramDialog = () => setIsNewProgramDialogOpen(!isNewProgramDialogOpen);
    const handleOnRightClick = (event) => {
        setX(event.pageX);
        setY(event.pageY);
        event?.preventDefault();
        event?.stopPropagation();
    };

    const resetPosition = (event) => {
        event?.stopPropagation();
        event?.preventDefault();
        setX(0);
        setY(0);
    };
    useEffect(() => {
        if (!_.isEmpty(patients) && !isLoading) {
            setCheckedPatientIds(patients.map((patient) => patient.patientId));
            setAllCurrentPatientsAreChecked(true);
        }
    }, [patients, isLoading]);
    const selectPatient = (patientId) => (event) => {
        window.open(PATIENT_PAGE.pathWithId(patientId), "_blank");
    };

    const checkPatient = (id) => {
        if (checkedPatientIds.includes(id)) {
            setCheckedPatientIds(checkedPatientIds.filter((item) => item !== id));
            setAllCurrentPatientsAreChecked(false);
        } else {
            const newCheckedIds = [...checkedPatientIds, id];
            setCheckedPatientIds(newCheckedIds);
            if (newCheckedIds.length === patients.length) {
                setAllCurrentPatientsAreChecked(true);
            }
        }
    };

    const checkAllPatientToggle = () => {
        if (allCurrentPatientsAreChecked) {
            setCheckedPatientIds([]);
            setAllCurrentPatientsAreChecked(false);
        } else {
            setCheckedPatientIds(patients.map((item) => item?.patientId));
            setAllCurrentPatientsAreChecked(true);
        }
    };
    const [sortParameter, setSortParameter] = useState("");
    const [reverseSort, setReverseSort] = useState(false);
    const onSort = (newSortParameter) => (event) => {
        setReverseSort(newSortParameter === sortParameter ? !reverseSort : false);
        setSortParameter(newSortParameter);
    };
    const [cohortName, setCohortName] = useState("");
    const [addCohort] = useAddCohortMutation();
    const [saveCohortDialogOpen, setSaveCohortDialogOpen] = useState(false);
    const openSaveCohortDialog = () => {
        setSaveCohortDialogOpen(true);
        setCohortName("");
    };
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const saveCohort = () => {
        addCohort({
            name: cohortName,
            originalEsQuery: JSON.stringify(query),
            patientIds: checkedPatientIds,
        })
            .unwrap()
            .then(() => {
                enqueueSuccess();
            })
            .catch((error) => {
                enqueueError();
            })
            .finally(() => {
                setSaveCohortDialogOpen(false);
            });
    };

    return (
        <Flex item container column>
            <Flex item container justifyContent={"flex-end"} style={{ marginTop: 10 }}>
                <Button
                    variant={"contained"}
                    onClick={openSaveCohortDialog}
                    disabled={_.isEmpty(checkedPatientIds)}
                    color={"primary"}
                >
                    {translate("cohort.save-cohort")}
                </Button>
            </Flex>
            <PatientHeader
                {...{
                    onSort,
                    sortParameter,
                    reverseSort,
                    translate,
                    allCurrentPatientsAreChecked,
                    checkAllPatientToggle,
                }}
            />
            <PatientListWindow {...{ patients: patients || [], selectPatient, checkedPatientIds, checkPatient, handleOnRightClick }} />
            <Flex
                item
                container
                grow={0}
                style={{
                    backgroundColor: "#F8F8FA",
                    height: 10,
                    borderRadius: "0px 0px 15px 15px",
                    border: "1px solid #E2E1E4",
                }}
            />
            <SaveCohortDialog
                {...{
                    saveCohortDialogOpen,
                    translate,
                    setCohortName,
                    cohortName,
                    setSaveCohortDialogOpen,
                    saveCohort,
                }}
            />
            {!!x && !!y && (
                <PatientRowContextMenu
                    {...{
                        x,
                        y,
                        resetPosition,
                        toggleNewProgramDialog,
                        checkedPatientIds,
                    }}
                />
            )}
            {isNewProgramDialogOpen && (
                <NewProgramDialog
                    {...{
                        patientList: patients.filter((item) => checkedPatientIds.includes(item.patientId))
                                .map(item=>({
                                    patientId: item.patientId,
                                    programNames: (item?.programs?.split(",") || [])
                                })),
                        cancel: toggleNewProgramDialog,
                        refetch: () => {},
                    }}
                />
            )}
        </Flex>
    );
};

export default CohortPatientList;
