import {AxiosBaseQuery} from "../../configs/Axios";
import {createApi} from "@reduxjs/toolkit/query/react";

export const MedicationPlan = 'MedicationPlan';
export const Ingredient = 'Ingredient';
export const Medicine = 'Medicine';
export const Anamnesis = 'Anamnesis';
export const Laboratory = 'Laboratory';
export const UserDetails = 'UserDetails';
export const PatientInvitedEmails = 'PatientInvitedEmails';
export const PatientCareProviders = 'PatientCareProviders';
export const CareProviders = 'CareProviders';
export const Patient = 'Patient';
export const PatientList = 'PatientList';
export const FILE = 'File';
export const DOCUMENT_LINK = 'DocumenLink';
export const AccessAssignments = 'AccessAssignments';
export const AGREEMENT = 'AGREEMENT';
export const PROGRAMS = 'PROGRAMS';
export const FILTERS = 'FILTERS';
export const COHORTS = 'COHORTS';

const tagTypes = [
    MedicationPlan,
    Ingredient,
    Medicine,
    Anamnesis,
    Laboratory,
    Patient,
    FILE,
    DOCUMENT_LINK,
    AccessAssignments,
    AGREEMENT,
    PROGRAMS,
    CareProviders
];

const HMOApi = createApi({
    reducerPath: 'hmoApi',
    tagTypes,
    baseQuery: AxiosBaseQuery({ baseUrl: '' }),
    endpoints: () => ({}),
    refetchOnMountOrArgChange: 20,
});
export default HMOApi;
