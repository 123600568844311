import * as Action from "./actions";

function formStructureReducer(state, action) {
    switch (action.type) {
        case Action.SET_INITIAL_STATE:
            return action.payload;
        case Action.ADD_RULE:
            return [...state, action.payload];
        case Action.UPDATE_RULE:
            return state.map((rule, index) => (index === action.index ? { ...rule, ...action.payload } : rule));
        case Action.REMOVE_RULE:
            return state.filter((_, index) => index !== action.index);
        default:
            return state;
    }
}

export default formStructureReducer;
