import {
    SwitchRenderer,
    RangeRenderer,
    DropdownRenderer,
    SimpleNumberRenderer,
    SwitchWithSelectRenderer,
    NestedMatch,
    TermRenderer,
    AutocompleteRenderer,
} from "../filter-components/searchInputRenderers/index";

import { WidgetType } from "./widgetTypes";

export const inputRendererMap = {
    [WidgetType.DROPDOWN]: DropdownRenderer,
    [WidgetType.AUTOCOMPLETE]: AutocompleteRenderer,
    [WidgetType.SIMPLE_NUMBER]: SimpleNumberRenderer,
    [WidgetType.SWITCH]: SwitchRenderer,
    [WidgetType.SWITCH_WITH_SELECT]: SwitchWithSelectRenderer,
    [WidgetType.RANGE_FILTER]: RangeRenderer,
    [WidgetType.NESTED]: NestedMatch,
    [WidgetType.TERM]: TermRenderer,
};
